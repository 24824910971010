<template>
    <div class="col-12 col-lg-6 g c">
        <div class="card card-body">
            <div class="form-group"><label for="">البوابة / مزود الخدمة</label><select v-model="sms_portal" class="form-control"><option value="">-- اختر البوابة --</option><option value="oursms">رسائلنا - oursms.net</option><option value="saudisms">رسائل السعودية - saudisms.net</option><option value="4jawaly">فور جوالي - 4jawaly</option><option value="malath">ملاذ اس ام اس</option><option value="yamamah">يمامه - Yamamah</option><option value="hisms">هاي اس ام اسم - hisms</option><option value="goldenskills">المهارات الذهبية - goldenskills</option><option value="shamelsms">الشامل - نور الشبكي</option><option value="bulksmssa">bulksms-sa</option><option value="jawalbsms">jawalbsms</option><option value="taqnyat">شركة تقنيات الجوال - taqnyat.sa</option><option value="vip1sms">تقنيات الرسالة - vip1sms.com</option><option value="orbit">المدار التقني - mobile.net.sa</option><option value="fsms">FSMS</option></select></div>
            <div class="form-group"><label for="">اسم المستخدم</label><input v-model="sms_username" type="text" class="form-control"></div>
            <div class="form-group"><label for="">كلمة المرور</label><input v-model="sms_password" type="password" class="form-control"></div>
            <div class="form-group"><label for="">اسم المرسل</label><input v-model="sms_sendername" type="text" class="form-control"></div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    حفظ
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            sms_portal: "",
            sms_username: "",
            sms_password: "",
            sms_sendername: ""
        }
    },
    created(){
        if(!checkPer("settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        this.getInfo()
    },
    methods: {
        getInfo(){
            var g = this;
             $.post(api + '/user/general/settings/sms', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.sms_portal = r.response.sms_portal
                    g.sms_username = r.response.sms_username
                    g.sms_password = r.response.sms_password
                    g.sms_sendername = r.response.sms_sendername
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
        save(){
            var g = this;
             $.post(api + '/user/general/settings/sms/save', {
                jwt: this.user.jwt,
                sms_portal: this.sms_portal,
                sms_username: this.sms_username,
                sms_password: this.sms_password,
                sms_portal: this.sms_portal,
                sms_sendername: this.sms_sendername
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم الحفظ", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>